import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, Checkbox, Drawer, Link, Modal, Stack, TextField, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';

import { Need, Simulation, VariableDate } from "../../model/Simulation";
import VariableDatePicker from "../VariableDatePicker";
import { modalStyles } from "../styles";
import AmountTextField from "../AmountTextField";
import { formatVariableAmount, formatVariableDate } from "../../uiUtils";
import { getVariableDateNumber } from "../../utils";

interface INeedsProps {
    simulation: Simulation | null;
    onSimulationChange: (simulation: Simulation) => void;
}

const Needs: React.FC<INeedsProps> = ({ simulation, onSimulationChange }) => {

    const [showNeeds, setShowNeeds] = React.useState<boolean>(false);
    const [showEditNeed, setShowEditNeed] = React.useState<boolean>(false);
    const [currentNeed, setCurrentNeed] = React.useState<Need | null>(null);
    const label = simulation===null?"": "(" + simulation?.needs.filter(i => i.active).length + " / " + simulation?.needs.length + ")";

    const handleAddNeed = () => {
        if(simulation) {
          const newNeed = {id: uuidv4(), name: 'New Need', amount: null, startDate: null, endDate: null} as Need;
          const newSimulation = {...simulation} as Simulation;
          newSimulation.needs.push(newNeed);
          onSimulationChange(newSimulation);
          setCurrentNeed(newNeed);
          setShowEditNeed(true);
        }
      }
    
      // const handleChangeNeed = (need: Need) => {
      //   setCurrentNeed(need);
      //   setShowEditNeed(true);
      // }
    
      const handleSaveNeed = () => {
        if(currentNeed) {
          const newSimulation = {...simulation} as Simulation;      
          
          newSimulation.needs.map((need, index) => {
            if(need.id === currentNeed.id) {
              newSimulation.needs[index] = currentNeed;
            }
            return need;
          });
    
          onSimulationChange(newSimulation);
          setShowEditNeed(false);
        }
      }
    
      const handleDeleteNeed = () => {
        if(currentNeed) {
          const newSimulation = {...simulation} as Simulation;      
          newSimulation.needs = newSimulation.needs.filter(need => need.id !== currentNeed.id);
          onSimulationChange(newSimulation);
          setShowEditNeed(false);
        }
      }

      const handleNeedActiveChange = (needToChange: Need) => {
        const newSimulation = {...simulation} as Simulation;      
        newSimulation.needs.map((need, index) => {
          if(need.id === needToChange.id) {
            newSimulation.needs[index].active = !newSimulation.needs[index].active;
          }
          return need;
        });
        onSimulationChange(newSimulation);
      }

    return <>
        <Modal open={showEditNeed} onClose={()=>setShowEditNeed(false)}>
          <Box sx={modalStyles}>
            <Typography variant="h6">Needs</Typography>
            <Stack direction="column" spacing={2} p={2}>
              <TextField label="Name" value={currentNeed?.name} onChange={(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>setCurrentNeed({...currentNeed!, name: e.target.value} ) } />
              <AmountTextField label="Amount" rates={simulation?.rates} value={currentNeed?.amount} onChange={a => setCurrentNeed({...currentNeed!, amount: a } ) } />
              <VariableDatePicker label="Start Date" investors={simulation?.investors} variableDate={currentNeed?.startDate} onVariableDateChange={(date: VariableDate|null) => setCurrentNeed({...currentNeed!, startDate: date} ) } />
              <VariableDatePicker label="End Date" investors={simulation?.investors} variableDate={currentNeed?.endDate} onVariableDateChange={(date: VariableDate|null) => setCurrentNeed({...currentNeed!, endDate: date} ) }  />
            </Stack>            
            <Stack direction="row" spacing={2} p={2}>
                <Button variant="contained" onClick={handleDeleteNeed} color="warning" >Delete</Button>
                <Button variant="contained" onClick={()=>setShowEditNeed(false)}>Cancel</Button>
                <Button variant="contained" onClick={handleSaveNeed}>Save</Button>
              </Stack>
          </Box>
        </Modal>

        <Drawer open={showNeeds} onClose={()=>setShowNeeds(false)}>
          <Box position="relative" sx={{ position: 'absolute', top: 0, right: 0, m: 2 }}>
            <Stack direction="row" spacing={2}>
              <Button variant="contained" onClick={()=>handleAddNeed()}>Add</Button>
              <Button variant="contained"  onClick={() => setShowNeeds(false)}>Close</Button>
            </Stack>
          </Box>
          <Typography variant="h5" p={2}>Needs</Typography>
          <Grid p={2}>
            <Box width="50vw">
              <Grid container size={12} columns={12}>
                <Grid size={1} textAlign="center">Active</Grid>
                <Grid size={5} textAlign="center">Name</Grid>                
                <Grid size={2} textAlign="center">Start Date</Grid>
                <Grid size={2} textAlign="center">End Date</Grid>
                <Grid size={2} textAlign="center">Amount</Grid>
                {simulation?.needs.sort((a, b) => (getVariableDateNumber(a.startDate) - getVariableDateNumber(b.startDate))).map((need, index) => {
                  const backgroundColor = index % 2 === 0 ? "white" : "whitesmoke";
                  const styles = {backgroundColor: backgroundColor, padding: 1, margin: 0};
                  return <React.Fragment key={need.id}>
                    <Grid sx={styles} size={1} textAlign="center">
                      <Checkbox defaultChecked={need.active} onChange={() => handleNeedActiveChange(need)} size="small" sx={{p:0}} />
                    </Grid>
                    <Grid sx={styles} size={5} textAlign="center">
                      <Stack direction="row" alignItems="center">
                        <Typography variant="body1"><Link href="#" onClick={()=>{setCurrentNeed(need);setShowEditNeed(true);}}>{need.name}</Link></Typography>
                      </Stack>
                    </Grid>
                    <Grid sx={styles} size={2}>
                      <Typography variant="body1" textAlign="center">{formatVariableDate(need.startDate, 'n/a')}</Typography>                      
                    </Grid>                    
                    <Grid sx={styles} size={2}>
                      <Typography variant="body1" textAlign="center">{formatVariableDate(need.endDate, simulation.endDate)}</Typography>
                    </Grid>
                    <Grid sx={styles} size={2}>
                      <Typography variant="body1" textAlign="center">{formatVariableAmount(need.amount)}</Typography>
                    </Grid>
                  </React.Fragment>
                }
                )}
              </Grid>
            </Box>
          </Grid>
        </Drawer>

        <Button variant="outlined" disabled={!simulation} onClick={()=>setShowNeeds(true)}>Needs {label}</Button>
    </>;
}

export default Needs;